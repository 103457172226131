import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";
import { theme } from "./theme.js";

import App, { appLoader } from "./App";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import getData from "./data.js";

const router = createBrowserRouter([
    {
        path: "*",
        element: <App />,
    }
])

const root = createRoot(document.getElementById("root"));
root.render(
    <RouterProvider router={router} />
);