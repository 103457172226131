export default async function getData(path) {
    return fetch("https://cars.kleinercode.com/path", 
        {
            body: JSON.stringify({path: path}),
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST"
        }
    )
}